<template>
  <div>
    <div class="p-center">
      <div class="header">
        <div class="logoBox">
          <img src="@/assets/images/logo.avif" alt="">
          {{time}}
        </div>
        <div class="hrefBox">
          <div class="hrefTXt" @click="toHref('/index')" :class="index==0?'lineHover':''">
            首页
            <div class="line"></div>
          </div>
          <div class="hrefTXt" @click="toHref('/drawNotice')" :class="index==1?'lineHover':''">
            開獎公告
            <div class="line"></div>
          </div>
          <div class="hrefTXt" @click="toHref('/live')" :class="index==2?'lineHover':''">
            開獎视频
            <div class="line"></div>
          </div>
          <div class="hrefTXt" @click="toHref('/faq')" :class="index==3?'lineHover':''">
            FAQ
            <div class="line"></div>
          </div>
          <div class="hrefTXt" @click="toHref('/about')" :class="index==4?'lineHover':''">
            關於我們
            <div class="line"></div>
          </div>
          <div class="hrefTXt" @click="toHref('/contact')" :class="index==5?'lineHover':''">
            聯繫我們
            <div class="line"></div>
          </div>
        </div>
      </div>
      <div style="height: 155px"></div>
    </div>
    <div class="m-center">
      <div class="header">
        <div class="headerT">
          <div class="logoBox">
            <img src="@/assets/images/logo.avif" alt="">
          </div>
<!--          <div class="selectBox">-->
<!--            <img src="@/assets/images/select.avif" alt="" v-if="!topShow" @click="openTop" class="seImg">-->
<!--            <img src="@/assets/images/close_icon.avif" alt="" v-else @click="closeTop" class="clImg">-->
<!--          </div>-->
        </div>
        <div class="hrefBox">
          <div class="hrefTXt" @click="toHref('/index')" :class="index==0?'lineHover':''">
            首页
            <div class="line"></div>
          </div>
          <div class="hrefTXt" @click="toHref('/drawNotice')" :class="index==1?'lineHover':''">
            開獎公告
            <div class="line"></div>
          </div>
          <div class="hrefTXt" @click="toHref('/live')" :class="index==2?'lineHover':''">
            開獎视频
            <div class="line"></div>
          </div>
          <div class="hrefTXt" @click="toHref('/faq')" :class="index==3?'lineHover':''">
            FAQ
            <div class="line"></div>
          </div>
          <div class="hrefTXt" @click="toHref('/about')" :class="index==4?'lineHover':''">
            關於我們
            <div class="line"></div>
          </div>
          <div class="hrefTXt" @click="toHref('/contact')" :class="index==5?'lineHover':''">
            聯繫我們
            <div class="line"></div>
          </div>
        </div>

      </div>
      <div style="height: 110px"></div>
      <div class="topCon" v-if="topShow">
        <div class="topCell" @click="toHref_m('/index')">
          <div>首页</div>
          <img src="@/assets/images/right_icon.avif" alt="">
        </div>
        <div class="topCell" @click="toHref_m('/drawNotice')">
          <div>開獎公告</div>
          <img src="@/assets/images/right_icon.avif" alt="">
        </div>
        <div class="topCell" @click="toHref_m('/live')">
          <div>開獎视频</div>
          <img src="@/assets/images/right_icon.avif" alt="">
        </div>
        <div class="topCell" @click="toHref_m('/faq')">
          <div>FAQ</div>
          <img src="@/assets/images/right_icon.avif" alt="">
        </div>
        <div class="topCell" @click="toHref_m('/about')">
          <div>關於我們</div>
          <img src="@/assets/images/right_icon.avif" alt="">
        </div>
        <div class="topCell" @click="toHref_m('/contact')">
          <div>聯繫我們</div>
          <img src="@/assets/images/right_icon.avif" alt="">
        </div>
      </div>
    </div>
    <div class="jz1"></div>
    <div class="jz2"></div>
    <div class="jz3"></div>
    <div class="jz4"></div>
    <div class="jz5"></div>
    <div class="jz6"></div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      index: 0,
      topShow: false,
      wcShow: false,
      foot: {},
      csListData: '',
      top: 0,
      time:''
    }
  },
  watch: {
    $route(to) {
      this.index = this.$route.meta.index
    },
  },
  mounted() {
    this.index = this.$route.meta.index
  },
  methods: {
    getCurDate(i) {
      var d = new Date();
      var week;
      switch (d.getDay()) {
        case 1:
          week = "星期一";
          break;
        case 2:
          week = "星期二";
          break;
        case 3:
          week = "星期三";
          break;
        case 4:
          week = "星期四";
          break;
        case 5:
          week = "星期五";
          break;
        case 6:
          week = "星期六";
          break;
        default:
          week = "星期天";
      }
      var years = d.getFullYear();
      var month = this.add_zero(d.getMonth() + 1);
      var days = this.add_zero(d.getDate());
      var hours = this.add_zero(d.getHours());
      var minutes = this.add_zero(d.getMinutes());
      // var seconds = this.add_zero(d.getSeconds());
      var ndate = years + "年" + month + "月" + days + "日 " + hours + ":" + minutes + " " + week;
      this.time = ndate
      if(i){
        setInterval(()=>{
          this.getCurDate()
        },30000)
      }
    },
    add_zero(temp) {
      if (temp < 10) return "0" + temp;
      else return temp;
    }
    ,
    toLogin() {
      window.open(this.foot.login_address)
    }
    ,
    toHref(row) {
      this.$router.push(row)
    }
    ,
    toHref_m(row) {
      this.$router.push(row)
      this.topShow = false
    }
    ,
    openTop() {
      this.topShow = true;
    }
    ,
    closeTop() {
      this.topShow = false;
    }
    ,
    opwcShow() {
      this.wcShow = true;
    }
    ,
  }
}
</script>

<style scoped lang="less">
a {
  text-decoration: none;
  color: #333
}

.p-center {
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    overflow: hidden;
    z-index: 9999;
    background-color: #ffffff;

    .logoBox {
      padding-left: 20%;
      padding-right: 20%;
      height: 85px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;

      img {
        width: 222px;
        height: 100%;
      }
    }
  }

  .hrefBox {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    height: 68px;
    background-color: #E1A001;
    padding-left: 20%;

    .hrefTXt {
      margin-right: 74px;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;

      .line {
        width: 28px;
        height: 4px;
        background: transparent;
        border-radius: 4px 4px 4px 4px;
        position: absolute;
        top: 30px;
      }

      &.lineHover {
        color: #134755;

        .line {
          background: #134755;
        }
      }
    }
  }
}

.m-center {
  .header {
    width: 100%;
    //padding: 0 5%;
    height: 110px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    z-index: 9999;
    .headerT{
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      height: 64px;
      width: 90%;
      margin: 0 auto;
    }

    .logoBox {
      //width: 39px;
      height: 39px;
      display: flex;
      border-radius: 4px 4px 4px 4px;

      img {
        width: auto;
        max-height: 100%;
        border-radius: 4px 4px 4px 4px;
      }
    }
  }

  .selectBox {
    .seImg {
      width: 25px;
      height: 21px;
    }

    .clImg {
      width: 15.5px;
      height: 15.5px;
    }
  }

  a {
    text-decoration: none
  }

  .topCon {
    background: #F3F3F3;
    position: fixed;
    z-index: 9998;
    top: 110px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 20px;

    .topCell {
      height: 50px;
      border-bottom: 1px solid #E5E5E5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      color: #333333;

      img {
        width: 10px;
        height: 14px;
      }
    }

    .phoneTip {
      display: none;
      position: absolute;
      justify-content: center;
      top: -40px;

      .wcImg {
        padding: 5px 10px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
        border-radius: 4px 4px 4px 4px;
        align-items: center;
        justify-content: center;
        display: flex;
      }

      .arrow {
        position: absolute;
        font-size: 30px;
        color: #ffffff;
        bottom: -18px;
      }
    }

    .wcTip {
      display: none;
      position: absolute;
      justify-content: center;
      top: -144px;

      .wcImg {
        width: 130px;
        height: 130px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
        border-radius: 4px 4px 4px 4px;
        align-items: center;
        justify-content: center;
        display: flex;

        img {
          width: 110px;
          height: 110px;
        }
      }

      .arrow {
        position: absolute;
        font-size: 30px;
        color: #ffffff;
        bottom: -18px;
      }
    }

    .serve {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .severCon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 12px;
        font-weight: 300;
        color: #666666;
        margin-top: 100px;
        position: relative;

        .severImg {
          width: 54px;
          height: 54px;
          margin-bottom: 10px;
        }
      }

      .severCon_wc:hover .wcTip {
        display: flex;
      }

      .severCon_phone:hover .phoneTip {
        display: flex;
      }
    }
  }
  .hrefBox {
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: space-around;
    font-weight: 400;
    color: #FFFFFF;
    height: 46px;
    background-color: #E1A001;
    //position: fixed;
    //bottom: 0;
    //left: 0;
    //right: 0;
    //z-index: 10000;

    .hrefTXt {
      margin-right: 4px;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;

      .line {
        width: 20px;
        height: 4px;
        background: transparent;
        border-radius: 4px 4px 4px 4px;
        position: absolute;
        top: 20px;
      }

      &.lineHover {
        color: #134755;

        .line {
          background: #134755;
        }
      }
    }
  }
}

.jz1{
  background-image: url(@/assets/images/0001.avif)!important;
  position: absolute;
  top:-9999px;
}
.jz2{
  background-image: url(@/assets/images/222.avif)!important;
  position: absolute;
  top:-9999px;
}
.jz3{
  background-image: url(@/assets/images/bg.fw.avif)!important;
  position: absolute;
  top:-9999px;
}
.jz4{
  background-image: url(@/assets/images/bj222.avif)!important;
  position: absolute;
  top:-9999px;
}
.jz5{
  background-image: url(@/assets/images/repeat.avif)!important;
  position: absolute;
  top:-9999px;
}
.jz6{
  background-image: url(@/assets/images/end.jpg)!important;
  position: absolute;
  top:-9999px;
}

</style>
