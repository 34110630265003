<template>
  <div>
    <div class="p-center">
      <div class="footBox">
        版權所有 不得轉載 © 香港日日彩
      </div>
    </div>
    <div class="m-center">
<!--      <div class="footBox">-->
<!--        版權所有 不得轉載 © 香港日日彩-->
<!--      </div>-->
      <div style="height: 46px"></div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      footData: {},
      index:0
    }
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style scoped lang="less">
a{text-decoration:none}
.p-center{
  .footBox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    font-size: 18px;
    font-weight: 300;
    color: #2C230D;
  }
}
.m-center{
  .footBox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 14px;
    font-weight: 300;
    color: #2C230D;
  }
}


</style>
