import axios from 'axios'
import Cookies from 'js-cookie'
import router from '../router'
import {Message} from "element-ui"

import {api_url} from './common'

const service = axios.create({
    baseURL: api_url
    // baseURL: '/ytapi'
    // timeout: 500000 // request timeout
})



// 全局请求处理
service.interceptors.request.use(
    config => {
        // if(localStorage.getItem('inCode')){
        //     config.headers['inCode'] = localStorage.getItem('inCode')
        // }else{
        //     // config.headers['inCode'] = localStorage.getItem('normalCode')
        //     config.headers['inCode'] = 'xuyx6cbz0lf92lrvvuypd0dmwcp89tdd'
        // }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)
// 全局返回处理
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.code !== 200) {
            resetMessage({
                message: res.msg,
                type: 'warning'
            })
            // 判断token值，对各种状态进行处理
            //未登录、过期：状态码 505
            if (res.code == 505) {
                Cookies.remove('ad_TOKEN')
                router.push({
                    path: '/login',
                    query: {
                        redirect: router.currentRoute.fullPath
                    }
                })
            }
        }
        return res
    },
    error => {
        Message({
            message: error,
            type: 'warning'
        })
        return Promise.reject(error)
    }
)
//防止弹出多个Message
let messageInstance = null;
const resetMessage = (options) => {
    if (messageInstance) {
        messageInstance.close()
    }
    messageInstance = Message(options)
};
['error', 'success', 'info', 'warning'].forEach(type => {
    resetMessage[type] = options => {
        if (typeof options === 'string') {
            options = {
                message: options
            }
        }
        options.type = type
        return resetMessage(options)
    }
})
export default service
