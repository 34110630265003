import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/', redirect: '/index' },
    {
        path: '/video',
        name: 'video',
        component: () => import('@/components/Video')
    },
    {
        path: '/index',
        name: 'index',
        component: Layout,
        children: [
            { path: '/index',name:'index', component: () => import('@/views/index/Index'), meta: { index: 0 } }
        ],
    },
    {
        path: '/drawNotice',
        name: 'drawNotice',
        component: Layout,
        children: [
            { path: '/drawNotice',name:'drawNotice', component: () => import('@/views/drawNotice/Index'), meta: { index: 1 } }
        ],
    },
    {
        path: '/live',
        name: 'live',
        component: Layout,
        children: [
            { path: '/live',name:'live', component: () => import('@/views/live/Index'), meta: { index: 2 } }
        ],
    },
    {
        path: '/faq',
        name: 'faq',
        component: Layout,
        children: [
            { path: '/faq',name:'faq', component: () => import('@/views/faq/Index'), meta: { index: 3 } }
        ],
    },
    {
        path: '/about',
        name: 'about',
        component: Layout,
        children: [
            { path: '/about',name:'about', component: () => import('@/views/about/Index'), meta: { index: 4 } }
        ],
    },
    {
        path: '/contact',
        name: 'contact',
        component: Layout,
        children: [
            { path: '/contact',name:'contact', component: () => import('@/views/contact/Index'), meta: { index: 5 } }
        ],
    },


]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
